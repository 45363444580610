import request from '@/utils/requestV2'
const qs = require('qs')

// 任务状态
export function getTaskStatus (data) {
  return request({
    url: '/ooh-msp/v2/status/gettaskstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 任务类型
export function getTaskType (data) {
  return request({
    url: '/ooh-msp/v2/status/gettasktype',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 画面来源
export function getPictureResource (data) {
  return request({
    url: '/ooh-msp/v2/status/getpictureresource',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 任务详情状态
export function getTaskItemStatus (data) {
  return request({
    url: '/ooh-msp/v2/status/gettaskitemstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 详情任务类型
export function getTaskitemType (data) {
  return request({
    url: '/ooh-msp/v2/status/gettaskitemtype',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 任务状态
export function getTaskProgressType (data) {
  return request({
    url: '/ooh-msp/v2/status/gettaskprogresstype',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取填充adType
export function getFillType (data) {
  return request({
    url: '/ooh-msp/v2/status/getfilltype',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 计费类型
export function getChargeStatus (data) {
  return request({
    url: '/ooh-msp/v2/status/getchargestatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取履约用途adType
export function getOrderAdType (data) {
  return request({
    url: '/ooh-msp/v2/status/getorderadtype',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取用途枚举类型
export function getAdTypes (data) {
  return request({
    url: '/ooh-msp/v2/status/getAdTypes',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 工单状态
export function getplanstatus (data) {
  return request({
    url: '/ooh-msp/v2/status/getplanstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}
